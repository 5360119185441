<template>
  <div>
    <horarios-superpuestos ref="superpuestos"></horarios-superpuestos>

    <calendario-horario ref="calendario"></calendario-horario>

    <horarios-carrera ref="horarios"></horarios-carrera>

    <alumno-cursado-form @close="reload" ref="form"></alumno-cursado-form>

    <h3>Tus inscripciones a Espacios Curriculares</h3>
    <div class="row mb-3">
      <div v-if="inscripcion" class="col-md">
        <v-btn color="primary" @click="addEspacio">Nuevo Espacio</v-btn>
      </div>
      <!--      <div class="col-md">-->
      <!--        <v-btn dark color="purple" @click="verHorarios">Ver Horarios</v-btn>-->
      <!--      </div>-->
      <div class="col-md">
        <v-btn dark @click="misHorarios"
          ><v-icon>mdi-calendar</v-icon> Mis Horarios</v-btn
        >
      </div>
      <div class="col-md">
        <v-btn
          target="_blank"
          href="https://drive.google.com/file/d/153CMXEoHJitiNgZc8Eo5s8TxHcL8j_-C/view"
          color="deep-purple"
          dark
        >
          Como realizar Encuesta
        </v-btn>
      </div>
    </div>

    <v-data-iterator :options.sync="options" :items="filteredAlumnos">
      <template v-slot:default="{}">
        <div v-for="(porCarrera, codigo) in porCarrera" :key="codigo">
          <v-alert color="primary" dark>
            {{ porCarrera.carrera.nombre }}
          </v-alert>
          <div
            class="mb-3"
            v-for="(porAnio, anio) in porCarrera.anios"
            :key="anio"
          >
            <v-alert color="warning" dark> {{ anio }}° AÑO </v-alert>
            <v-row>
              <v-col
                v-for="item in porAnio"
                cols="12"
                md="6"
                lg="4"
                xl="3"
                :key="item.id"
              >
                <v-card dark :color="getCardColor(item)">
                  <v-card-title>
                    <div class="w-100">
                      <v-alert
                        class="text-break subtitle-2"
                        v-if="item.cursadosSuperpuestos.length > 0"
                        color="error"
                      >
                        Este Horario tiene Superposición!!!
                        <v-btn small color="purple" @click="misHorarios"
                          >Ver en Calendario</v-btn
                        >
                      </v-alert>
                      <v-alert
                        color="warning"
                        v-if="!esAlumno"
                        class="p-2 subtitle-2 text-break"
                        >Pendiente de aprobación en Secretaría</v-alert
                      >

                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            v-if="
                              esFormacionDocente(item) &&
                              item.cursado.cuatrimestre === 'primero'
                            "
                            target="_blank"
                            href="https://forms.gle/MDgLZUPPUpF5wf5x8"
                            block
                            class="mb-2"
                            color="deep-purple"
                            >Realizar Encuesta</v-btn
                          >
                          <div v-if="item.notaAlumnoCursado.cerrado">
                            <v-alert
                              block
                              v-if="item.notaAlumnoCursado.notificado"
                              :color="getEstadoColor(item)"
                            >
                              <div>
                                <div
                                  v-if="
                                    item.notaAlumnoCursado.condicion ===
                                    'promocionado'
                                  "
                                >
                                  Estado:
                                  {{ item.notaAlumnoCursado.condicion }} con
                                  {{ item.notaAlumnoCursado.nota_promocion }}
                                </div>
                                <div v-else>
                                  Estado: {{ item.notaAlumnoCursado.condicion }}
                                </div>
                              </div>
                            </v-alert>
                            <div v-else>
                              <v-btn
                                @click="mostrarNota(item.notaAlumnoCursado)"
                                block
                                color="primary"
                                >Ver Calificación</v-btn
                              >
                            </div>
                          </div>
                          <div class="title text-break">
                            {{ item.cursado.espacio.nombre }}
                          </div>
                          <div class="subtitle-1">
                            Año Lectivo: {{ item.cursado.anio_lectivo }}
                          </div>
                          <div class="subtitle-1">
                            Comisión: {{ item.cursado.comision }}
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <!--                      <v-col cols="12">-->
                      <!--                        <div class="subtitle-2">-->
                      <!--                          <span>-->
                      <!--                            Inicio:-->
                      <!--                            {{ item.cursado.fecha_inicio }}-->
                      <!--                          </span>-->
                      <!--                          <v-spacer></v-spacer>-->
                      <!--                          <span> Fin: {{ item.cursado.fecha_fin }} </span>-->
                      <!--                        </div>-->
                      <!--                      </v-col>-->
                      <v-col cols="12">
                        <div class="subtitle-2">
                          {{ item.cursado.horariosFormateados.corto }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <div class="d-flex flex-wrap w-100">
                      <!--                      <div v-if="item.notaAlumnoCursado.cerrado">-->
                      <!--                        <div-->
                      <!--                            class="p-2"-->
                      <!--                            v-if="item.notaAlumnoCursado.notificado"-->
                      <!--                        >-->
                      <!--                          <span>El alumno se ha Notificado</span>-->
                      <!--                        </div>-->
                      <!--                        <div-->
                      <!--                            v-else-->
                      <!--                            class="flex m-1">-->
                      <!--                          <v-btn block @click="notificarme(item.id)" color="warning">-->
                      <!--                            Notificarme-->

                      <!--                          </v-btn>-->
                      <!--                        </div>-->
                      <!--                      </div>-->

                      <!--                      <div class="flex m-1">-->
                      <!--                        <v-btn-->
                      <!--                          color="warning"-->
                      <!--                          block-->
                      <!--                          target="_blank"-->
                      <!--                          :href="item.cursado.aula_campus"-->
                      <!--                        >-->
                      <!--                          <v-icon>mdi-school</v-icon>-->
                      <!--                          Aula Campus-->
                      <!--                        </v-btn>-->
                      <!--                      </div>-->
                      <div
                        v-if="inscripcion && item.cursado.puedeInscribirse"
                        class="flex m-1"
                      >
                        <v-btn block @click="borrarItem(item.id)" color="error">
                          <v-icon>mdi-delete</v-icon>
                          Eliminar
                        </v-btn>
                      </div>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import AlumnoCursadoForm from "@/flavor/alumno/views/Inscripciones/cursado/AlumnoCursadoForm";
import { mapActions, mapGetters, mapState } from "vuex";
import HorariosCarrera from "@/flavor/alumno/views/Inscripciones/cursado/HorariosCarrera";
import CalendarioHorario from "@/viewsNew/cursado/CalendarioHorario";
import HorariosSuperpuestos from "@/viewsNew/alumno-cursado/HorariosSuperpuestos";
export default {
  name: "AlumnoCursadosList",
  components: {
    HorariosSuperpuestos,
    CalendarioHorario,
    HorariosCarrera,
    AlumnoCursadoForm,
  },
  data() {
    return {
      options: {},
    };
  },
  computed: {
    ...mapState("CorteInscripcionCarreraModule", ["corteInscripcionCarreras"]),
    ...mapState("NewAlumnoCursadoModule", ["alumnoCursados"]),
    ...mapGetters("NewAlumnoCursadoModule", ["porCarrera"]),
    ...mapState("UserModule", ["user"]),

    filteredAlumnos() {
      return this.alumnoCursados.filter((el) =>
        el.alumno.roles.includes("alumno")
      );
    },

    inscripcion() {
      return this.corteInscripcionCarreras.length === 1;
    },

    esAlumno() {
      return this.user.roles.find((el) => el === "alumno");
    },
  },
  methods: {
    ...mapActions("CorteInscripcionCarreraModule", [
      "getCorteInscripcionCarreras",
    ]),
    ...mapActions("NewAlumnoCursadoModule", [
      "getMisCursadas",
      "deleteAlumnoCursado",
    ]),
    ...mapActions("NotaAlumnoCursadoModule", ["notificarme"]),

    esFormacionDocente(item) {
      if (["PEI", "PEP", "PESB"].includes(item.carrera.codigo)) {
        return true;
      }
      return false;
    },

    mostrarNota(nota) {
      console.log("notificarme", nota);
      this.notificarme(nota.id);
      this.reload();
      this.getCorteInscripcionCarreras({
        filters: { tipo: "cursado", activo: 1 },
      });
    },

    getEstadoColor(item) {
      let clase = "p-1 title ";
      if (
        item.notaAlumnoCursado.condicion === "aprobado" ||
        item.notaAlumnoCursado.condicion === "promocionado"
      ) {
        clase += " bg-primary";
      } else if (item.notaAlumnoCursado.condicion === "sin informar") {
        clase += " bg-warning";
      } else {
        clase += " bg-danger";
      }
      return clase;
    },

    getCardColor(item) {
      if (!this.esAlumno || item.cursadosSuperpuestos.length > 0) {
        return "secondary";
      }

      return "success";
    },

    addEspacio() {
      this.$refs.form.dialog = true;
    },
    async borrarItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteAlumnoCursado(id);
      }
    },
    reload() {
      this.getMisCursadas({
        expand: ["cursado", "carrera", "cursadosSuperpuestos"].toString(),
      });
    },
    verHorarios() {
      this.$refs.horarios.dialog = true;
    },
    misHorarios() {
      this.$refs.calendario.cursos = this.filteredAlumnos.map(
        (el) => el.cursado
      );
      this.$refs.calendario.dialog = true;
    },
  },
  created() {
    this.reload();
    this.getCorteInscripcionCarreras({
      filters: { tipo: "cursado", activo: 1 },
    });
  },
};
</script>

<style scoped></style>
